<template>
	<div>
		<div class="veadmin-iframe-header veadmin-iframe-header-custom-fixed">
			<span>当前位置:</span>
			企业形象<span>&gt;</span>
			时间线
			<button class="veadmin-iframe-button" onclick="location.reload();">
				<i class="el-icon-refresh-right"></i>
			</button>
		</div>
		<div class="veadmin-iframe-content veadmin-iframe-content-custom-fixed">
			<el-row>
				<el-button type="primary" icon="el-icon-circle-plus-outline" style="margin:10px 68px;" @click="addTimeline()">增加时间线</el-button>
			</el-row>
			<el-timeline>
				<el-timeline-item v-for="(item, count) in timelineList" :key="count.toString()" :timestamp="item.timestamp" placement="top">
					<el-button type="primary" icon="el-icon-edit" style="margin-bottom:4px;" @click="editTimeline(item.id)">修改</el-button>
					<el-button type="danger" icon="el-icon-delete" style="margin-bottom:4px;" @click="deleteTimeline(item.id)">删除</el-button>
					<el-card style="width:500px;">
						<div v-html="item.text"></div>
					</el-card>
				</el-timeline-item>
			</el-timeline>
		</div>
	</div>
</template>

<script>
import { apiListTimelineByCondition, apiDeleteTimeline } from "@/api/admin/timeline.js";

export default {
	data() {
		return {
			condition: { status: "" },
			timelineList: []
		};
	},
	methods: {
		//获取时间线
		async listTimeline() {
			try {
				let formData = new FormData();
				formData.append("status", this.condition.status);
				/* for (let [a, b] of formData.entries()) {
					console.log(a + ":", b || '""');
				} */
				const response = await apiListTimelineByCondition(this.$store.state.token, formData);
				//console.log(response);
				if (response.data.code == 200) {
					//console.log(response.data.data);
					this.timelineList = response.data.data;
				} else {
					this.$message.error(response.data.message);
				}
			} catch (error) {
				console.log(error);
			}
		},
		addTimeline() {
			this.$router.push("/pc/admin/timeline/add");
		},
		editTimeline(id) {
			this.$router.push("/pc/admin/timeline/edit?id=" + id);
		},
		async deleteTimeline(id) {
			try {
				let formData = new FormData();
				formData.append("id", id);
				/* for (let [a, b] of formData.entries()) {
					console.log(a + ":", b || '""');
				} */
				const response = await apiDeleteTimeline(this.$store.state.token, formData);
				//console.log(response);
				if (response.data.code == 200) {
					this.$message({ message: "成功删除", type: "success" });
					//执行成功1秒后刷新页面
					setTimeout(function() {
						location.reload();
					}, 1000);
				} else {
					this.$message.error(response.data.message || response.data.exception);
				}
			} catch (error) {
				console.log(error);
			}
		}
	},
	mounted() {
		//页面渲染完成调用方法获取数据
		this.listTimeline();
	}
};
</script>

<style lang="scss" scoped></style>
